
import MSocket from '@/api/socket'
import { defineComponent } from 'vue'

const TIMEOUT = 5000

export default defineComponent({
  name: 'ErrorMessage',
  data () {
    return {
      error: '',
      show: false,
      color: 'red',
      progressbar: 100,
      hideTimeout: false as number | false,
      hideInterval: false as number | false
    }
  },
  mounted () {
    MSocket.onError.addEventListener(errorid => {
      this.showError(errorid)
    })

    this.$error.addEventListener(({ id, message, color }) => {
      this.showError(id, message, color)
    })
  },
  methods: {
    showError (id?: string, message?: string, color?: string) {
      if (id) {
        this.error = this.idToMessage(id)
      } else {
        this.error = message || 'Unknown error.\n If the error persist please contact us.'
      }

      this.progressbar = 100
      this.color = color || 'red'
      this.show = true
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout)
      }
      if (this.hideInterval) {
        clearInterval(this.hideInterval)
      }
      this.hideTimeout = setTimeout(() => {
        this.show = false
        this.hideTimeout = false
        if (this.hideInterval) {
          clearInterval(this.hideInterval)
        }
      }, TIMEOUT)
      this.hideInterval = setInterval(() => {
        this.progressbar--
      }, TIMEOUT / 100)
    },
    close () {
      this.show = false
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout)
      }
      this.hideTimeout = false
    },
    idToMessage (errorId: string) {
      switch (errorId) {
        case 'UserNotFound':
          return 'Couldn\'t find this user.'
        case 'NameNotValid':
          return 'Sorry this username is already taken.'
        case 'AlreadyInGame':
          return 'Seems like you are already in a game.\nIf the error persist please contact us.'
        case 'NotInGame':
          return 'You are not in a game.'
        case 'MessageTooLong':
          return 'The message is too long.'
        case 'MessageRequired':
          return 'The message can\'t be empty'
        default:
          return `Unknown error: "${errorId}".\nIf the error persist please contact us.`
      }
    }
  }
})
